<template>
  <div class="w4">
    <div class="title-lockup">
      <span class="home-title static-word">Useful</span>
      <transition name="word" mode="out-in"><div class="home-title" :key="word" :style="`color: ${color}`">{{ word }}</div></transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'UsefulTitle',
  data() {
    return {
      word: '',
      color: '',
      words: ['advice', 'tips', 'tricks', 'resources'],
      colors: ['#0076D6', '#028844', '#FFD500', '#E22922'],
    };
  },
  mounted() {
    let i = 0;
    this.word = this.words[i];
    this.color = this.colors[i];

    setInterval(() => {
      this.word = this.words[i];
      this.color = this.colors[i];
      i++;
      if (i >= this.words.length) {
        i = 0;
      }
    }, 2000);
  },
});
</script>

<style lang="scss" scoped>


.title-lockup {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.static-word {
  padding-right: 1rem;
}
.home-title {
  font-size: 68px;
  line-height: 68px;
  margin: 102px 0 72px 0;
  font-family: 'Lyon-Display';

  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 36px;
    line-height: 36px;
    margin: 64px 0;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 42px;
    line-height: 42px;
    margin: 96px 0 64px 0;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 56px;
    line-height: 56px;
    margin: 102px 0 72px 0;
  }
}

.word-enter-active {
  animation: word-in .15s;
}
.word-leave-active {
  animation: word-out .15s;
}
@keyframes word-in {
  0% {
    transform: translateY(-300px);
    opacity: 0;
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes word-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
