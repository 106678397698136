<template>
  <div ref="animatedStages" class="animated-stages">
    <div class="animated-stage" :id="`stage${i}`" :class="animated" v-for="(stage, i) in stageData" :key="i" :style="`border-color: ${stage.color}`">
      <svg class="arrow" width="33px" height="24px" viewBox="0 0 33 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
              <path d="M14.8882981,10.2676874 C14.86466,10.2469956 14.8351124,10.2405294 14.8099969,10.222424 L3.78283178,0.568379448 C2.91708703,-0.189459816 1.51505331,-0.189459816 0.64930856,0.568379448 C-0.216436187,1.32621871 -0.216436187,2.55350448 0.64930856,3.31134374 L10.1754555,11.6501621 L0.64930856,19.9902737 C-0.216436187,20.748113 -0.216436187,21.9753988 0.64930856,22.733238 C1.08218093,23.1121577 1.6494949,23.3009709 2.21680886,23.3009709 C2.78412282,23.3009709 3.34995941,23.1121577 3.78283178,22.733238 L14.8099969,13.0791935 C14.8351124,13.0597949 14.86466,13.0533287 14.8882981,13.0339301 C15.3241252,12.652424 15.5383453,12.1506464 15.5340459,11.6501621" id="path-1"></path>
          </defs>
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-297, -1682)">
                  <g transform="translate(281, 1658)">
                      <g>
                          <g transform="translate(16, 24)">
                              <g transform="translate(16.466019, 0)">
                                  <mask id="mask-2" fill="white">
                                      <use xlink:href="#path-1"></use>
                                  </mask>
                                  <use :fill="stage.color" xlink:href="#path-1"></use>
                              </g>
                              <rect :fill="stage.color" x="0" y="9.63106796" width="29.8252427" height="4.03883495" rx="2.01941748"></rect>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      <span class="title slate-medium">{{ stage.title }}</span>
      <span class="info slate-light" v-html="stage.description" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'AnimatedStages',
  props: {
    stageData: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data() {
    return {
      animated: '',
      intersectedOnce: false, // has the intersection callback been called at least once?
    };
  },
  computed: {
  },
  mounted() {
    function intersectionCallback(entries) {
      entries.forEach(entry => {
        if (this.intersectedOnce) {
          if (entry.isIntersecting) {
              if (entry.intersectionRatio >= 1) {
                this.animated = 'animated';
                this.observer.unobserve(this.$refs.animatedStages);
              }
          }
        } else {
          // intersection observer callback is also triggered on page load. We must
          // avoid this.
          this.intersectedOnce = true;
        }
      });
    }

    var options = {
      root: null,
      rootMargin: '-20px 0px 0px 0px', // don't start intersection until 20px after stages are in view
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(intersectionCallback.bind(this), options);
    this.observer.observe(this.$refs.animatedStages);
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.animatedStages);
  },
}
</script>

<style lang="scss" scoped>
.animated-stages {
  display: flex;
  margin-top: 16px;
  overflow: hidden;
  // small
  @media screen and (max-width: $medium - 1) {
    flex-wrap: wrap;
  }
}

.animated-stage {
  display: flex;
  flex-direction: column;
  flex: 1 1 20%;
  justify-content: stretch;
  align-content: flex-start;
  border-top: solid 3px white;
  padding: 16px;
  position: relative;
  transform: translateY(0);
  min-height: 150px;

  // small
  @media screen and (max-width: $medium - 1) {
    padding: 0 4px;
    flex: 0 0 50%;
  }

  @media screen and (min-width: $medium) {
    min-height: 280px;
  }

  @media screen and (min-width: $extra-large) {
    margin-top: 32px;
  }
}

.arrow {
  position: absolute;
  top: 16px;
  left: 6%;

  // small
  @media screen and (max-width: $medium - 1) {
    width: 16px;
    top: 8px;
    left: 0%;
  }
}

.title {
  margin-bottom: 8px;
  margin-top: 32px;

  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 18px;
    line-height: 22px;
    margin-top: 32px;
    margin-bottom: 4px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 20px;
    line-height: 24px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 25px;
    line-height: 30px;
    margin-top: 48px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 30px;
    line-height: 36px;
    margin-top: 48px;
  }
}


.info {
  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 14px;
    line-height: 20px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 16px;
    line-height: 22px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 18px;
    line-height: 24px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 22px;
    line-height: 32px;
  }
}



/*
  Transition work
*/

$transition-duration: 700ms;  // how long each individual step takes to resolve
$transition-delay: 700ms;     // time between steps being kicked off

.animated-stage.animated {
    transform: translateY(0);
    transition: all $transition-duration ease-in-out;

    .arrow {
      margin-left: -32px;
      left: 100%;
      transition: all $transition-duration ease-in-out;
    }
  }

#stage0 {
  transition: none;
  &.animated {
    .arrow {
      transition-delay: $transition-delay;
    }
  }
}

#stage1 {
  transform: translateY(32px);
  &.animated {
    transition-delay: $transition-delay;
    transform: translateY(0);
    .arrow {
      transition-delay: $transition-delay * 2;
    }
  }
}

#stage2 {
  transform: translateY(64px);
  &.animated {
    transform: translateY(0);
    transition-delay: $transition-delay * 2;
    .arrow {
      transition-delay: $transition-delay * 3;
    }
  }
}

#stage3 {
  transform: translateY(96px);

  // small
  @media screen and (max-width: $medium - 1) {
    transform: translateY(32px);
  }


  &.animated {
    transform: translateY(0);
    transition-delay: $transition-delay * 3;
    .arrow {
      transition-delay: $transition-delay * 4;
    }
  }
}

#stage4 {
  transform: translateY(128px);

  // small
  @media screen and (max-width: $medium - 1) {
    transform: translateY(64px);
  }

  &.animated {
    transform: translateY(0);
    transition-delay: $transition-delay * 4;
    .arrow {
      transition-delay: $transition-delay * 5;
    }
  }
}

</style>
