<template>
  <div class="useful-article">
    <div class="newgrid newgrid-no-row-gap newgrid-full-bleed newgrid-no-max">
      <div class="grid-block">
        <div class="bg-image-container">
          <picture>
            <source media="(max-width: 620px)" srcset="~@/assets/images/useful_hero_sm.png" />
            <source media="(max-width: 899px)" srcset="~@/assets/images/useful_hero_md.png" />
            <source media="(max-width: 1439px)" srcset="~@/assets/images/useful_hero_lg.png" />
            <source media="(min-width: 1440px)" srcset="~@/assets/images/useful_hero_xl.png" />
            <img
              alt="Patchwork of L&amp;G article images"
              class="bg-image"
              src="~@/assets/images/useful_hero_lg.png"
            />
          </picture>
          <div class="newgrid newgrid-no-row-gap newgrid-no-max">
            <div class="grid-block grid-block-4col grid-block-small-full bg-image-titling">
              <!-- main subheading -->
              <h1 class="utopia-blk-headline main-subheading">
                Make it
                <span class="useful-color">Useful:</span>
              </h1>
              <!-- main title -->
              <h2
                class="utopia-blk-headline main-title"
              >Lessons in corporate Learning &amp; Development</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-4col">
        <div class="body-copy-group body-copy-group__1">
          <p
            class="standfirst slate-medium"
          >Whether you've been with a company for a week or ten years plus, we're all too familiar with the feeling of despair when you can't find what you need, when you need it.</p>
          <p
            class="slate-light body-copy"
          >We worked with financial services company Legal &amp; General to make a tool that’s focused solely on serving people at their moment in need.</p>
          <p
            class="slate-light body-copy"
          >Experience and research tells us that end users don’t care how a product is built or where it comes from. The only thing it has to be, really, is Useful. So that's what we called it, and that's what it is. A performance-support resource hub that provides immediate, no-nonsense answers to the questions we know the users have.</p>
        </div>
        <h2 class="subtitle-large utopia-blk-headline">The approach</h2>
      </div>
    </div>

    <div class="newgrid">
      <div class="grid-block">
        <animated-stages :stage-data="stageData" />
      </div>
    </div>

    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-4col">
        <div class="body-copy-group body-copy-group__1">
          <h2 class="subtitle-large subtitle utopia-blk-headline">The solution</h2>
          <h3 class="subtitle utopia-blk-headline">Put employees first</h3>
          <p
            class="slate-light body-copy"
          >Accessing resources and support should be easy - it should be an intuitive experience, one which is much closer to consumer services experienced in our daily lives.</p>
          <p
            class="slate-light body-copy"
          >By providing just-in-time resources on a bespoke site, tailored specifically to serve L&amp;G people, we demonstrate that we care most about what they need to know, as opposed to what the business wants to tell them.</p>
        </div>
        <div class="body-copy-group body-copy-group__1">
          <useful-title />
        </div>

        <div class="body-copy-group body-copy-group__1">
          <h3 class="subtitle utopia-blk-headline">Ideas before technology</h3>
          <div class="quotation quotation-big">
            <p
              class="quotation-text utopia-cap-italic"
            >“I suppose it is tempting, if the only tool you have is a hammer, to treat everything as if it were a nail.”</p>
            <p class="quotation-attribute slate-light">- Abraham Maslow, 1966.</p>
          </div>
          <p
            class="slate-light body-copy"
          >Approaching challenges from the point-of-view of a hammer casting around for nails begins with the tools themselves and then attempts to fit the problem to them. When imagining a new, innovative employee experience - why limit ourselves to the tools we already have?</p>
          <p
            class="slate-light body-copy"
          >We decided to push against the assumption that we’d build using the common tools of the business, such as Sharepoint. Instead we focussed on solving pain points using common, simple web technologies, allowing us to fit the solution exactly to the problem.</p>
        </div>
      </div>
    </div>

    <!-- video -->
    <div class="with-caption video-with-caption">
      <div class="newgrid newgrid-no-row-gap newgrid-small-col-reverse">
        <div
          class="grid-block grid-block-1col grid-block-right-padding grid-block-small-full video-with-caption__container"
        >
          <div class="caption-group">
            <h4 class="caption-title utopia-cap">Useful in action</h4>
            <!-- <p class="caption-body slate-light">Some text for the video caption within Useful</p> -->
          </div>
        </div>
        <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-small-full">
          <app-video
            class="with-caption__media"
            :poster="require('@/assets/images/useful-carousel/hometop_xl.png')"
            :sources="[{ src: require('@/assets/videos/useful-video.mp4'), type: 'mp4' }]"
          />
        </div>
      </div>
    </div>

    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-4col">
        <!-- image intro  -->
        <div class="body-copy-group body-copy-group__1">
          <h3 class="subtitle utopia-blk-headline">Reach every employee</h3>
          <p
            class="slate-light body-copy"
          >Everyone needs support but there are some people at L&amp;G who, for technical reasons, are unable to access any. Contractors, for example, often find themselves far removed and excluded from key learning resources.</p>
          <p
            class="slate-light body-copy"
          >By offering two clean and concise methods of site access we made sure to reach all communities and allow everyone access to a brand new learning experience.</p>
        </div>

        <div class="body-copy-group body-copy-group__1">
          <h3 class="subtitle utopia-blk-headline">Streamline the experience</h3>
          <p class="slate-light body-copy">
            To make Useful even more - well - useful, we wanted to smoothly integrate into the working lives of L&amp;G people. We’re working with L&amp;G's IT team to make our content searchable by their main Intranet search, and to incorporate Useful into Microsoft Teams, putting it at the fingertips of everyone "
            <a
              href="https://joshbersin.com/2018/06/a-new-paradigm-for-corporate-training-learning-in-the-flow-of-work/"
            >in the flow of work</a>".
          </p>
        </div>

        <div class="body-copy-group body-copy-group__1">
          <h3 class="subtitle utopia-blk-headline">Test, iterate and repeat</h3>
          <p
            class="slate-light body-copy"
          >Useful is unique, rare and different. It can flex, shift and grow in any direction desired. It can become a native app, it can add new functionality and content, and it can evolve in any way.</p>
          <p
            class="slate-light body-copy"
          >We're lucky to have an incredible client at L&amp;G who doesn't just see the value of short, testable iterations, but who really wants to push this approach as much as possible. It allows us to be more agile, to serve the needs of the client and its staff faster, and more specifically. By doing this, we make certain that as the service evolves, it remains what it claims to be: Useful.</p>
        </div>
      </div>
    </div>

    <!-- carousel -->
    <image-carousel :carousel-data="carouselData" :light="true" />

    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-4col outro">
        <!-- outro -->
        <div class="body-copy-group body-copy-group__5">
          <div class="testimonial">
            <p
              class="utopia-dis-italic testimonial-text"
            >Working with Tui is a dream. They worked closely with Brij Palicha and Joseph Livingstone in the L&G Talent and Development team to really understand our challenges, then used their expertise to create a beautiful, useful (excuse the pun!) tool that fits seamlessly into the lives of our people.</p>
            <p class="slate-light testimonial-attribute">- Gemma Paterson, L&amp;G</p>
          </div>
        </div>

        <div class="body-copy-group body-copy-group__5">
          <h2 class="subtitle subtitle-large utopia-blk-headline">We love problems</h2>
          <p class="slate-light body-copy">
            Talk to us to discuss your employee learner experiences or other challenges. We're interested in designing, building out and testing digital services for big businesses. Perhaps we can help you too?
            <a
              class="slate-bold"
              href="mailto:contact@tuimedia.com"
            >Get in touch</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedStages from "@/components/AnimatedStages";
import ImageCarousel from "@/components/ImageCarousel";
import AppVideo from "@/components/AppVideo";
import UsefulTitle from "@/components/UsefulTitle";

export default {
  name: "UsefulArticle",
  data() {
    return {
      stageData: [
        {
          color: "#E12922",
          title: "Discover",
          description: "Speak to employees, collaborate with IT teams."
        },
        {
          color: "#0176D6",
          title: "Focus",
          description:
            'Evolve challenges into <span class="slate-light-italic">"How Might We?"</span>'
        },
        {
          color: "#FFD400",
          title: "Design & Build",
          description: "Begin with a proof-of-concept"
        },
        {
          color: "#038844",
          title: "Test",
          description:
            'Fail <span class="slate-light-italic">forwards</span> and change direction if needed'
        },
        {
          color: "#6FCAF3",
          title: "Iterate",
          description: '<span class="slate-medium">Repeat.</span>'
        }
      ],
      carouselData: [
        {
          imagePath: "useful-carousel/articletop",
          title: "Article Page",
          description:
            "Topics are given a rich article page, answering common questions",
          alt: "Screenshot of an article page"
        },
        {
          imagePath: "useful-carousel/hometile",
          title: "Home page tiles",
          description:
            "Sections on the homepage are arranged into a tessellated block",
          alt: "Screenshot of a section of the Useful home page"
        },
        {
          imagePath: "useful-carousel/hometop",
          title: "Home page",
          description: "A clean, no-nonsense page greets L&G staff",
          alt: "Screenshot of the top of the Useful home page"
        },
        {
          imagePath: "useful-carousel/managertips",
          title: "Tip and tricks",
          description: "Articles include straightforward tips and tricks",
          alt:
            "Screenshot of a section of an article page, showing tips for managers"
        },
        {
          imagePath: "useful-carousel/thingstotry",
          title: "Things to try",
          description:
            "Article pages often include recommendations of things to try",
          alt: "Screenshot of a 'things to try' section on an article page"
        },
        {
          imagePath: "useful-carousel/aidmodel",
          title: "Model behaviour",
          description:
            "Acronym models are used to help people remember useful techniques",
          alt: "Screenshot of an acronym model on an article page"
        }
      ]
    };
  },
  metaInfo() {
    return {
      title: "Make it Useful: Lessons in corporate Learning & Development",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
        {
          vmid: "ogUrl",
          property: "og:url",
          content: "https://www.tuimedia.com" + this.$route.fullPath
        },
        {
          property: "og:type",
          content: "website",
          vmid: "ogType"
        },
        {
          vmid: "ogTitle",
          property: "og:title",
          content: "Make it Useful: Lessons in corporate Learning & Development"
        },
        {
          vmid: "ogDescription",
          property: "og:description",
          content:
            "We worked with financial services company Legal & General to make a tool that's focused solely on serving people in their moment of need."
        },
        {
          property: "og:image",
          content:
            "https://www.tuimedia.com" +
            require("@/assets/images/useful_hero_sm.png"),
          vmid: "ogImage"
        },
        {
          property: "og:image:width",
          content: 256,
          vmid: "ogImageWidth"
        },
        {
          property: "og:image:height",
          content: 256,
          vmid: "ogImageHeight"
        },
        {
          property: "og:site_name",
          content: "Tui Media"
        },
        {
          vmid: "twitterCard",
          name: "twitter:card",
          content: "summary"
        },
        {
          name: "twitter:image",
          content:
            "https://www.tuimedia.com" +
            require("@/assets/images/useful_hero_sm.png"),
          vmid: "twitterImage"
        },
        {
          name: "twitter:image:alt",
          content: "L&G Useful site thumbnail",
          vmid: "twitterImageAlt"
        },
        {
          vmid: "twitterTitle",
          name: "twitter:title",
          content: "Make it Useful: Lessons in corporate Learning & Development"
        },
        {
          vmid: "twitterSite",
          name: "twitter:site",
          content: "@tui_uk"
        },
        {
          vmid: "themeColor",
          name: "theme-color",
          content: "#1A1A24"
        }
      ]
    };
  },
  components: {
    AnimatedStages,
    ImageCarousel,
    AppVideo,
    UsefulTitle
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/objects.media-with-caption";

.useful-article {
  color: #000;
  margin-bottom: 96px;
  margin-top: -96px;

  // small
  @media screen and (max-width: $medium - 1) {
    margin-bottom: 32px;
  }
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-bottom: 64px;
  }
}

.bg-image {
  width: 100%;

  &-container {
    position: relative;
    margin-bottom: 32px;

    & ~ * {
      z-index: 1;
    }

    @media screen and (min-width: $large) {
      margin-bottom: 64px;
    }
  }

  &-titling {
    position: absolute;
    bottom: 0;
    max-width: 100%;

    // large
    @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
      width: 57%;
    }

    // extra-large
    @media screen and (min-width: $extra-large) {
      margin-left: 16%;
    }
  }
}

.main-subheading {
  color: black;

  .useful-color {
    color: #ffd500;
  }

  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 24px;
    line-height: 27px;
    margin-top: 80px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 26px;
    line-height: 30px;
    margin-top: 110px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 32px;
    line-height: 36px;
    margin-top: 180px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 40px;
    line-height: 44px;
    margin-top: 200px;
  }
}

.main-title {
  color: #fff;

  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 33px;
    line-height: 36px;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 41px;
    line-height: 44px;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 48px;
    line-height: 51px;
    margin-top: 8px;
    margin-bottom: 38px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 64px;
    line-height: 64px;
    margin-top: 8px;
    margin-bottom: 56px;
    max-width: 1000px;
  }
}

.standfirst {
  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 21px;
    line-height: 31px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 19px;
    line-height: 25px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 21px;
    line-height: 27px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 24px;
    line-height: 35px;
  }
}

.body-copy {
  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 18px;
    line-height: 28px;

    &-group__1 {
      margin-bottom: 26px;
    }
    &-group__4 {
      margin-top: 24px;
      margin-bottom: 16px;
    }
    &-group__5 {
      margin-top: 32px;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 15px;
    line-height: 21px;

    &-group__1 {
      margin-bottom: 32px;
    }
    &-group__4 {
      margin-top: 16px;
      margin-bottom: 40px;
    }
    &-group__5 {
      margin-top: 40px;
    }
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 16px;
    line-height: 24px;

    &-group__1 {
      margin-bottom: 48px;
    }

    &-group__2 {
      margin-top: 8px;
    }
    &-group__4 {
      margin-top: 24px;
      margin-bottom: 64px;
    }
    &-group__5 {
      margin-top: 64px;
    }
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 18px;
    line-height: 27px;

    &-group__1 {
      margin-bottom: 80px;
    }

    &-group__4 {
      margin-top: 32px;
      margin-bottom: 80px;
    }
    &-group__5 {
      margin-top: 80px;
    }
  }

  a,
  a:link {
    font-family: "SlateStd-Medium";
    text-decoration: none;
  }

  a:hover,
  a:focus {
    color: #0176d6;
    text-decoration: underline;
  }
  a:visited {
    color: #666;
  }
}

.standfirst,
.body-copy,
.subtitle,
.subtitle-large,
.body-copy-group {
  @media screen and (max-width: $medium - 1) {
  }

  /* at medium widths the content-well spans 4 out of the 5 columns */
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
  }
}

.subtitle-large {
  margin-bottom: 16px;

  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 24px;
    line-height: 28px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 26px;
    line-height: 30px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 32px;
    line-height: 36px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 40px;
    line-height: 44px;
  }
}

.subtitle-large + h3 {
  margin-top: 32px;
}

.subtitle + p {
  margin-top: 11px;
}

.video-with-caption {
  background: #f6f6f6;

  // small
  @media screen and (max-width: $medium - 1) {
    margin-bottom: 32px;

    &__container {
      margin-bottom: 16px;
      row-gap: 24px;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-bottom: 32px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    margin-bottom: 67px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    margin-bottom: 96px;
  }
}

.image-with-caption {
  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-bottom: 16px;
  }
  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    margin-bottom: 24px;
  }
  // extra-large
  @media screen and (min-width: $extra-large) {
    margin-top: -16px;
    margin-bottom: 32px;
  }
}

.outro {
  // small
  @media screen and (max-width: $medium - 1) {
    margin-top: 24px;
  }
}

.quotation {
  margin-top: 16px;
  margin-bottom: 32px;

  &-text {
    font-size: 36px;
    line-height: 50px;
    margin-top: 16px;

    // large
    @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 16px;
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: $large - 1) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    // small
    @media screen and (max-width: $medium - 1) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.testimonial {
  margin-top: 16px;
  margin-bottom: 32px;

  &-text {
    font-size: 20px;
    line-height: 34px;
    margin-top: 16px;

    // large
    @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    // medium and small
    @media screen and (max-width: $large - 1) {
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 16px;
    }
  }
}
</style>
