<template>
  <div class="gsk-article">
    <div class="newgrid">
      <div class="grid-block">
        <div class="bg-image-container">
          <picture>
            <source media="(max-width: 620px)" srcset="~@/assets/images/GSK_bg_sm.png">
            <source media="(max-width: 899px)" srcset="~@/assets/images/GSK_bg_md.png">
            <source media="(max-width: 1439px)" srcset="~@/assets/images/GSK_bg_lg.png">
            <source media="(min-width: 1440px)" srcset="~@/assets/images/GSK_bg_xl.png">
            <img
              alt="GSK worker"
              class="bg-image"
              src="~@/assets/images/GSK_bg_lg.png"
              />
            </picture>
            <h1 class="utopia-blk-headline main-title">GSK: Digital onboarding for new leaders</h1>
        </div>
      </div>
    </div>



    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <p class="standfirst slate-regular">We recently worked with GSK to look at designing a digital onboarding service for new joiners with leadership experience. We took a typically user-centred approach, to build something that changed behaviours, not only for the new joiners, but also their line managers.</p>
      </div>
    </div>

    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h2 class="utopia-blk-headline">A user-centred process</h2>
      </div>
    </div>

    <!-- stages go here -->
    <div class="newgrid">
      <div class="grid-block process-steps">
        <div class="process-step process-step--first">
          <p class="process-step__number utopia-blk-headline">1</p>
          <h3 class="process-step__title slate-medium">Discover</h3>
          <p class="process-step__description slate-light">Speak to the end users to understand their needs, concerns and pain-points. In this case, we spoke to over 40 GSK leaders who had  recently been onboarded.</p>
        </div>

        <div class="process-step">
          <p class="process-step__number utopia-blk-headline">2</p>
          <h3 class="process-step__title slate-medium">Focus</h3>
          <p class="process-step__description slate-light">
Identify and prioritise the problems we can solve. For each problem, generate a How Might We? question to be answered during the design phase.</p>
        </div>

        <div class="process-step">
          <p class="process-step__number utopia-blk-headline">3</p>
          <h3 class="process-step__title slate-medium">Design &amp; build</h3>
          <p class="process-step__description slate-light">Starting with rapid, collaborative idea generation, design and build a Minimum Viable Product solution.</p>
        </div>

        <div class="process-step">
          <p class="process-step__number utopia-blk-headline">4</p>
          <h3 class="process-step__title slate-medium">Test</h3>
          <p class="process-step__description slate-light">Go back to the users to see where the new system succeeds and fails.</p>
        </div>

        <div class="process-step process-step--last">
          <p class="process-step__number utopia-blk-headline">5</p>
          <h3 class="process-step__title slate-medium">Iterate</h3>
          <p class="process-step__description slate-light">Back to the focus phase to make further improvements.</p>
        </div>

      </div>
    </div>

    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h2 class="utopia-blk-headline">Pain points</h2>
        <p class="slate-light">We found 4 main concerns:</p>
        <p class="slate-light tight">The level of line manager engagement was the number one predictor of the onboarding outcome. The more they’re involved, the better the outcome.</p>
        <p class="slate-medium">How might we encourage and support line managers through onboarding?</p>

        <p class="slate-light tight">A daunting cliff-face of work on day 1. Lots to do, and no priority or structure about when to do it.</p>
        <p class="slate-medium">How might we reduce or structure this workload?</p>

        <p class="slate-light tight">Connecting with the right people. New joiners often had no visibility of  the people they needed to know and work with.</p>
        <p class="slate-medium">How might we provide early and useful connections to new joiners?</p>

        <p class="slate-light tight">Acronym-central. There’s a strange new idiom with an array of new three-letter acronyms to understand.</p>
        <p class="slate-medium">How might we help new joiners discreetly discover the language of GSK?</p>
      </div>
    </div>


    <!-- carousel -->
    <!-- <image-carousel :carousel-data="carouselData" :light="true" /> -->


    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h2 class="utopia-blk-headline">Our solution</h2>
        <p class="slate-light">Our four concerns turned into five main design goals.</p>
      </div>
    </div>


    <div class="newgrid newgrid-small-col">
      <div class="grid-block grid-block-2col grid-block-small-full grid-block-right-padding">
        <picture>
          <source media="(max-width: 620px)" srcset="~@/assets/images/gsk/ex-tasks_sm.png">
          <source media="(max-width: 899px)" srcset="~@/assets/images/gsk/ex-tasks_md.png">
          <source media="(max-width: 1439px)" srcset="~@/assets/images/gsk/ex-tasks_lg.png">
          <source media="(min-width: 1440px)" srcset="~@/assets/images/gsk/ex-tasks_xl.png">
          <img
            alt="Example task tiles, including 'your 90 day onboarding journey', and 'Meet your team and manager'"
            src="~@/assets/images/gsk/ex-tasks_lg.png"
            />
        </picture>
      </div>
      <div class="grid-block grid-block-2col pull-quote">
        <p class="utopia-cap-italic">It's important not to underestimate the power of a checklist.</p>
      </div>
    </div>


    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h3 class="slate-medium">Provide structure and guidance</h3>
        <p class="slate-light">We built the home page of our service as a big interactive checklist of things to do, structured to provide guidance on when to do each thing . It's important not to underestimate <a href="https://en.wikipedia.org/wiki/The_Checklist_Manifesto">the power of a checklist</a>: they provide a quick overview of what's to be done, the order in which to do these things , and a sense of progress as the items are checked off.</p>
        <p class="slate-light">We were also careful to arrange the information in other ways too, for users who'd been through the checklist and needed performance support, or who preferred their information arranged by topic.</p>
      </div>
    </div>


    <div class="newgrid newgrid-half-row-gap example-image">
      <div class="grid-block grid-block-small-full grid-block-4col grid-block-left-margin-1col">
        <picture>
          <source media="(max-width: 620px)" srcset="~@/assets/images/gsk/ex-timeline_lg.png">
          <source media="(max-width: 899px)" srcset="~@/assets/images/gsk/ex-timeline_lg.png">
          <source media="(max-width: 1439px)" srcset="~@/assets/images/gsk/ex-timeline_xl.png">
          <source media="(min-width: 1440px)" srcset="~@/assets/images/gsk/ex-timeline_xl.png">
          <img
            alt="Example timeline, showing the stages of the onboarding, and where the user currently is within them"
            src="~@/assets/images/gsk/ex-timeline_lg.png"
            />
        </picture>
      </div>
    </div>

    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h3 class="slate-medium">Help people before they start</h3>
        <p class="slate-light">There was a clear appetite for digital support before day 1, so we built a system that extended into the pre-join phase, providing non-confidential information and advice to new joiners as a means of lessening the initial cliff-face on day one.</p>
        <p class="slate-light">Technically this was done by providing two logins, (one single-sign-on through the company's systems, and another simple username/password service for pre-joiners). We spent a lot of time making it simple for users to consolidate these two logins once new joiners received their company ID.</p>
      </div>
    </div>

    <div class="newgrid newgrid-small-col newgrid-half-row-gap example-image">
      <div class="grid-block grid-block-2col grid-block-small-full grid-block-right-padding">
        <img
          alt="An example 'person to meet' tile, showing details about the person, with buttons to 'mark as met' or 'add a note'"
          src="~@/assets/images/gsk/ex-person_sm.png"
          class="meet-person"
          />
      </div>
      <div class="grid-block grid-block-2col grid-block-small-full pull-quote pull-quote-pulled-left">
        <p class="utopia-cap-italic">The New Leaders site has ‘humanised’ the onboarding process by facilitating a sense of connection.</p>
      </div>
    </div>

    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h3 class="slate-medium">Get connected</h3>
        <p class="slate-light">We included a People to meet page, populated by information added by the line manager including specific notes about colleagues  from the manager. New joiners can also see the profile pictures of their team members and senior stakeholders. They are encouraged to connect with as many people as possible, just to introduce themselves and kick-start a solid working relationship.</p>
        <p class="slate-light">The New Leaders site has 'humanised' the onboarding process, by facilitating a sense of connection (via the personalised email and friendly faces in people to meet). This is especially important when teams are spread across the globe. The previous offerings were just bombarding new joiners with information, without suggesting or bringing opportunities for any human interaction.</p>
      </div>
    </div>

    <div class="newgrid example-image newgrid-small-col newgrid-half-row-gap">
      <div class="grid-block grid-block-small-full grid-block-2col grid-block-left-margin-1col grid-medium-full pull-quote">
        <p class="utopia-cap-italic">The big benefit of a bot is that it doesn’t judge you if you don’t know what PHAT stands for in the pharmaceutical industry.</p>
      </div>
      <div class="grid-block grid-block-2col grid-block-small-full right-justified">
          <img
            alt="Example task tiles, including 'your 90 day onboarding journey', and 'Meet your team and manager'"
            src="~@/assets/images/gsk/ex-jargonbot.png"
            class="jargonbot-image"
            />
      </div>
    </div>


    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h3 class="slate-medium">JargonBot</h3>
        <p class="slate-light">To help deal with the acronym-overload at GSK, we made a small bot that could answer questions about wording using a central database. The big benefit of a bot is that it doesn’t judge you if you don’t know what PHAT stands for in the pharmaceutical industry.</p>
        <p class="slate-light">Because we built the bot as a cloud service, we were also able to add it to GSK’s collaboration platform, so it was available for quick reference wherever the new joiner was working.</p>
      </div>
    </div>


    <div class="newgrid newgrid-small-col newgrid-half-row-gap example-image">
      <div class="grid-block grid-block-3col grid-block-small-full grid-block-medium-full grid-medium-full">
        <img
          alt="An example 'person to meet' tile, showing details about the person, with buttons to 'mark as met' or 'add a note'"
          src="~@/assets/images/gsk/ex-hm.png"
          />
      </div>
    </div>


    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h3 class="slate-medium">Make line-manager engagement intrinsic</h3>
        <p class="slate-light">Our system allows line managers to set up the tasks and connections for the new starters, generating a 'welcome to GSK' email that comes directly from them, and provides a unique, personalised experience for new starters.
          This required some difficult conversations; those complaining that line managers are too busy to help onboard their reports were reminded that this is a fundamental responsibility. An important goal here was to shift the culture of management to be more people-focused and empowering.</p>
      </div>
    </div>


    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <!-- <div class="testimonial">
          <p class="utopia-cap-italic testimonial-text">&ldquo;This is where the quote from Grant will go, if he's kind enough to provide one for us. Let's hope he is!&rdquo;</p>
          <p class="slate-medium testimonial-attribute">People Development Director, GSK</p>
        </div> -->

        <div class="testimonial">
          <p class="utopia-cap-italic testimonial-text">&ldquo;I took a peek at this onboarding portal for new hires and I must say THIS IS AWESOME! The business really needs this and if all recruiters and HR partners saw this we would have a party.&rdquo;</p>
          <p class="slate-medium testimonial-attribute">User testing participant</p>
        </div>

        <div class="testimonial">
          <p class="utopia-cap-italic testimonial-text">&ldquo;A very useful way to onboard, especially remotely.&rdquo;</p>
          <p class="slate-medium testimonial-attribute">GSK New Leader</p>
        </div>
      </div>
    </div>


    <div class="newgrid">
      <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-left-margin-1col">
        <h2 class="subtitle subtitle-large utopia-blk-headline">We love problems</h2>
        <p class="slate-light body-copy">Talk to us to discuss your onboarding or other challenges. We're interested in designing, building out and testing digital services for big businesses. Perhaps we can help you too? <a class="slate-bold" href="mailto:contact@tuimedia.com">Get in touch</a>. </p>
      </div>
    </div>

  </div>
</template>

<script>

// import ImageCarousel from '@/components/ImageCarousel';

export default {
  name: 'GSKArticle',
  data() {
    return {
      // carouselData: [
      //   {
      //     imagePath: 'useful-carousel/articletop',
      //     title: 'Article Page',
      //     description: 'Topics are given a rich article page, answering common questions',
      //     alt: 'Screenshot of an article page',
      //   },
      //   {
      //     imagePath: 'useful-carousel/hometile',
      //     title: 'Home page tiles',
      //     description: 'Sections on the homepage are arranged into a tessellated block',
      //     alt: 'Screenshot of a section of the Useful home page',
      //   },
      //   {
      //     imagePath: 'useful-carousel/hometop',
      //     title: 'Home page',
      //     description: 'A clean, no-nonsense page greets L&G staff',
      //     alt: 'Screenshot of the top of the Useful home page',
      //   },
      //   {
      //     imagePath: 'useful-carousel/managertips',
      //     title: 'Tip and tricks',
      //     description: 'Articles include straightforward tips and tricks',
      //     alt: 'Screenshot of a section of an article page, showing tips for managers',
      //   },
      //   {
      //     imagePath: 'useful-carousel/thingstotry',
      //     title: 'Things to try',
      //     description: 'Article pages often include recommendations of things to try',
      //     alt: 'Screenshot of a \'things to try\' section on an article page',
      //   },
      //   {
      //     imagePath: 'useful-carousel/aidmodel',
      //     title: 'Model behaviour',
      //     description: 'Acronym models are used to help people remember useful techniques',
      //     alt: 'Screenshot of an acronym model on an article page',
      //   },
      // ],
    };
  },
  components: {
    // ImageCarousel,
  },
};
</script>

<style lang="scss" scoped>

.gsk-article {
  margin-bottom: 96px;
  margin-top: -96px;
  color: #333;

  // small
  @media screen and (max-width: $medium - 1) {
    margin-bottom: 32px;
  }
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-bottom: 64px;
  }
}

.bg-image-container {
  display: flex;
  justify-content: flex-end;
  min-height: 320px;
  margin-left: -32px;
  margin-right: -32px;

  .bg-image {
    flex: 0 0 auto;

    // small
    @media screen and (max-width: $medium - 1) {
      position: absolute;
      left: -32px;
      width: 150%;
    }

    @media screen and (min-width: $medium) and (max-width: $large - 1) {
      position: absolute;
      top: 0;
      right: -32px;
      width: 80%;
    }
  }
}

.main-title {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  padding: 32px 32px 64px 0;
  background-color: #fff;

  font-size: 64px;
  line-height: 1em;

  @media screen and (max-width: $medium - 1) {
    font-size: 32px;
    width: 100%;
    padding-bottom: 16px;
    background-color: rgba(255,255,255,0.9);
    padding: 16px;
    padding-left: 0;
  }
  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 40px;
    width: 75%;
    padding-bottom: 16px;
  }
  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 48px;
  }
}

p.tight {
  margin-bottom: 0;
}


.process-steps {
  display: flex;
  margin-top: 16px;
  margin-bottom: 32px;

  @media screen and (max-width: $medium - 1) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.process-step {
  flex: 1 1 20%;
  background-color: #eee;
  padding: 10px;
  margin: 0 5px;

  @media screen and (max-width: $medium - 1) {
    flex: 0 0 46.5%;
    margin: 0;
    margin-bottom: 10px;
    padding: 8px;
  }

  &--first {
    margin-left: 0;
  }

  &--last {
    margin-right: 0;

    @media screen and (max-width: $medium - 1) {
      flex: 0 0 100%;
    }
  }

  &__number {
    font-size: 64px;
    line-height: 64px;
    margin: 0;
    color: $gsk-orange;
  }
}


.meet-person {
  width: 70%;

  // small
  @media screen and (max-width: $medium - 1) {
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    width: 60%;
  }

}

.example-image {
  margin-top: 64px;
}

.pull-quote {
  font-size: 30px;
  line-height: 36px;
  color: #666;

  @media screen and (max-width: $medium - 1) {
    margin-top: 16px;
  }


  &-pulled-left {
    margin-left: -10%;
    width: 50%;

    // small
    @media screen and (max-width: $medium - 1) {
      margin-left: 0;
      width: 100%;
      margin-top: 16px;
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: $large - 1) {
      margin-left: -20%;
      width: 55%;
    }
  }
}

.right-justified {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  & > * {
    flex: 0 0 auto;
  }

  // small
  @media screen and (max-width: $medium - 1) {
    justify-content: flex-start;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    width: 40%;
    padding-left: 24px;
  }
}

.testimonial {
  margin-top: 64px;

  &:last-child {
    margin-bottom: 64px;
  }

  // small
  @media screen and (max-width: $medium - 1) {
    margin-top: 32px;

    &:last-child {
      margin-bottom: 32px;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-top: 40px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    margin-top: 48px;

    &:last-child {
      margin-bottom: 48px;
    }
  }



  &-text {
    font-size: 36px;
    line-height: 50px;
    color: $gsk-orange;
    margin-bottom: 8px;

    // small
    @media screen and (max-width: $medium - 1) {
      font-size: 24px;
      line-height: 34px;
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: $large - 1) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &-attribute {
    color: #333;
  }

}



</style>
